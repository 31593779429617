import axios from 'axios';
import router from '@/router';
import { getLocalStorage, isVariableEmpty, clearLoginInfo } from '@/utils';
import { Message } from 'element-ui';

const http = axios.create({
  timeout: 1000 * 30,
  baseURL: '/'
});

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  return {
    ...config,
    url: process.env.VUE_APP_BASE_API + config.url,
    method: config.method || 'GET',
    headers: config.headers ? getTokenHeader(config.method || 'GET') : getHeader(),
    data: config.data || '',
    emulateJSON: config.emulateJSON || true
  };
}, error => {
  return Promise.reject(error);
});

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  return response;
}, error => {
  switch (error.response.status) {
    case 400:
      Message({
        message: error.response.data,
        type: 'error',
        duration: 1500,
        customClass: 'element-error-message-zindex'
      });
      break;
    case 401:
      clearLoginInfo();
      router.push({ path: '/login' });
      break;
    case 500:
      Message({
        message: ('连接失败，请稍后重试！'),
        type: 'error',
        duration: 1500,
        customClass: 'element-error-message-zindex'
      });
      break;
  }
  return Promise.reject(error);
});

const Problem = function(error, standard) {
  console.log('连接失败', error);
  const result = {};
  result.status = 400;
  result.message = '  ';
  return standard || result;
};

const getHeader = function() {
  return {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
    'platform': 'pc'
  };
};

const getTokenHeader = function(type) {
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
    'token': getToken(),
    'platform': 'pc'
  };
  return headers;
};

const getToken = function() {
  if (isVariableEmpty(getLocalStorage('LoginUser')) || isVariableEmpty(getLocalStorage('LoginUser').token)) {
    return '';
  }
  return getLocalStorage('LoginUser').token;
};

export {
  http,
  Problem
};
